<template>
  <label class="input-container" :class="{error: error && error.length > 0}">
    <span v-if="label" class="input-label">{{ label }}</span>
    <input :type="type" :value="value" @input="$emit('input', $event.target.value)" @change="update($event.target.value)" v-bind="$attrs"
           class="input"  :placeholder="placeholder">
    <transition name="fade">
      <p v-if="error && error.length > 0" class="input-error">{{ error }}</p>
    </transition>
  </label>
</template>

<script>
export default {
  name: "AInput",
  props: {
    autocomplete: String,
    label: String,
    placeholder: {
      type: String,
      default() {
        return 'Enter...'
      }
    },
    type: {
      type: String,
      default() {
        return 'text'
      }
    },
    error: String,
    value: [String, Number],
  },
  methods: {
    update(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>

</style>
